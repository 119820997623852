import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import styled from "styled-components";
import ContiPlaceholder from "../components/ContiPlaceholder";
import {
  formatRelativeTime,
  formatTotalDuration,
  parseLocalDateString,
} from "../utils/formatDuration";
import {
  Container,
  ContiItem,
  ContiImageWrapper,
  ContiImage,
  InfoText,
  ContiTitle,
  Subtitle,
  SongInfo,
  EmptyStateContainer,
  EmptyStateImage,
  EmptyStateText1,
  EmptyStateText2,
} from "./MyPage";
import { getUserNickname, getConties } from "../utils/axios";
import { ContiType } from "../types";

const ContiList = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
`;

const MyUploadedContis: React.FC = () => {
  const navigate = useNavigate();
  const [uploadedContis, setUploadedContis] = useState<ContiType[] | null>(
    null
  );
  const [nickname, setNickname] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [contiesResponse, userNickname] = await Promise.all([
          getConties(),
          getUserNickname(),
        ]);

        const conties = Array.isArray(contiesResponse)
          ? contiesResponse
          : contiesResponse.contiData || [];

        const filteredContis = conties.filter(
          (conti: ContiType) =>
            conti.state !== "DELETED" && conti.User.nickname === userNickname
        );

        setUploadedContis(filteredContis);
        setNickname(userNickname);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setIsError(true);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleContiClick = useCallback(
    (id: number) => {
      navigate(`/conti-detail/${id}`);
    },
    [navigate]
  );

  if (isLoading) {
    return (
      <Container
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <EmptyStateContainer
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          <EmptyStateImage src="/images/WhitePiano.png" alt="Loading..." />
          <EmptyStateText1>로딩 중입니다...</EmptyStateText1>
          <EmptyStateText2>잠시만요...</EmptyStateText2>
        </EmptyStateContainer>
      </Container>
    );
  }

  if (isError) {
    return (
      <Container
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <EmptyStateContainer
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          <EmptyStateImage src="/images/WhitePiano.png" alt="Error" />
          <EmptyStateText1>에러가 발생했어요!</EmptyStateText1>
          <EmptyStateText2>콘티를 불러오는 데 실패했어요.</EmptyStateText2>
        </EmptyStateContainer>
      </Container>
    );
  }

  return (
    <Container
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      {uploadedContis && uploadedContis.length > 0 ? (
        <ContiList>
          {uploadedContis.map((data, index) => (
            <ContiItem
              key={data.id}
              onClick={() => handleContiClick(data.id)}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <ContiImageWrapper>
                <ContiPlaceholder size={100} />
                <ContiImage
                  src={data.thumbnail || "/images/WhitePiano.png"}
                  alt="Album Image"
                  style={{
                    height:
                      !data.thumbnail ||
                      data.thumbnail === "/images/WhitePiano.png"
                        ? "62px"
                        : "100px",
                  }}
                />
              </ContiImageWrapper>
              <InfoText>
                <ContiTitle>{data.title}</ContiTitle>
                <Subtitle>{nickname || "사용자"}</Subtitle>
                <SongInfo>{`${formatRelativeTime(
                  parseLocalDateString(data.updatedAt)
                )} • ${formatTotalDuration(data.duration)}`}</SongInfo>
              </InfoText>
            </ContiItem>
          ))}
        </ContiList>
      ) : (
        <EmptyStateContainer
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.3 }}
        >
          <EmptyStateImage src="/images/WhitePiano.png" alt="Empty state" />
          <EmptyStateText1>앗!</EmptyStateText1>
          <EmptyStateText2>아직 업로드한 콘티가 없어요.</EmptyStateText2>
        </EmptyStateContainer>
      )}
    </Container>
  );
};

export default MyUploadedContis;

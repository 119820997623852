import styled from "styled-components";

interface SafariSpaceProps {
  $isFocused: boolean;
}

const SafariSpaceContainer = styled.div<SafariSpaceProps>`
  width: 100%;
  height: ${(props) => (props.$isFocused ? "0" : "134px")};
  background-color: #e7f0fc;
  position: fixed;
  bottom: 0;
  display: ${(props) => (props.$isFocused ? "none" : "flex")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: height 0.3s ease, display 0.3s ease;
  padding: 10px 0;
`;

const AddressBar = styled.div`
  width: 90%;
  height: 36px;
  background-color: #f5f5f5;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const AddressText = styled.span`
  font-size: 14px;
  font-weight: 500;
  color: #000000;
`;

const SafariContent = styled.div`
  width: 90%;
  height: 80px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
`;

const Icon = styled.svg`
  width: 24px;
  height: 24px;
`;

const BottomBar = styled.div`
  width: 139px;
  height: 5px;
  background-color: #000;
  border-radius: 2.5px;
  margin-top: 10px;
`;

const SafariSpace: React.FC<SafariSpaceProps> = ({ $isFocused }) => {
  return (
    <SafariSpaceContainer $isFocused={$isFocused}>
      <AddressBar>
        <AddressText>contied.com</AddressText>
      </AddressBar>
      <SafariContent>
        <Icon width="12" height="19" viewBox="0 0 12 19" fill="none">
          <path
            d="M9.41504 18.2197C9.58936 18.394 9.81494 18.4966 10.0815 18.4966C10.6147 18.4966 11.0146 18.0967 11.0146 17.5635C11.0146 17.3071 10.9121 17.0713 10.748 16.897L3.2832 9.59619L10.748 2.29541C10.9121 2.12109 11.0146 1.875 11.0146 1.62891C11.0146 1.0957 10.6147 0.685547 10.0815 0.685547C9.81494 0.685547 9.58936 0.788086 9.41504 0.962402L1.29395 8.90918C1.09912 9.07324 0.986328 9.32959 0.986328 9.59619C0.986328 9.85254 1.08887 10.0884 1.28369 10.2832L9.41504 18.2197Z"
            fill="#2E7CF6"
          />
        </Icon>
        <Icon width="12" height="19" viewBox="0 0 12 19" fill="none">
          <path
            d="M1.91943 18.5068C2.18604 18.5068 2.41162 18.4043 2.58594 18.23L10.7173 10.2832C10.9121 10.0884 11.0146 9.85254 11.0146 9.59619C11.0146 9.32959 10.9121 9.0835 10.7173 8.90918L2.59619 0.972656C2.41162 0.788086 2.18604 0.685547 1.91943 0.685547C1.38623 0.685547 0.986328 1.0957 0.986328 1.62891C0.986328 1.875 1.08887 2.12109 1.25293 2.29541L8.71777 9.59619L1.25293 16.897C1.08887 17.0713 0.986328 17.3071 0.986328 17.5635C0.986328 18.0967 1.38623 18.5068 1.91943 18.5068Z"
            fill="#CECECE"
          />
        </Icon>
        <Icon width="20" height="24" viewBox="0 0 20 24" fill="none">
          <path
            d="M10 15.3857C10.4409 15.3857 10.8101 15.0166 10.8101 14.5859V4.05518L10.7485 2.51709L11.4355 3.24512L12.9941 4.90625C13.1377 5.07031 13.353 5.15234 13.5479 5.15234C13.9683 5.15234 14.2964 4.84473 14.2964 4.42432C14.2964 4.20898 14.2041 4.04492 14.0503 3.89111L10.5845 0.54834C10.3794 0.343262 10.2051 0.271484 10 0.271484C9.78467 0.271484 9.61035 0.343262 9.40527 0.54834L5.93945 3.89111C5.78564 4.04492 5.69336 4.20898 5.69336 4.42432C5.69336 4.84473 6.00098 5.15234 6.43164 5.15234C6.62646 5.15234 6.85205 5.07031 6.99561 4.90625L8.5542 3.24512L9.24121 2.51709L9.17969 4.05518V14.5859C9.17969 15.0166 9.55908 15.3857 10 15.3857ZM4.11426 23.4146H15.8755C18.0186 23.4146 19.0952 22.3481 19.0952 20.2358V10.0024C19.0952 7.89014 18.0186 6.82373 15.8755 6.82373H13.0146V8.47461H15.8447C16.8599 8.47461 17.4443 9.02832 17.4443 10.0947V20.1436C17.4443 21.21 16.8599 21.7637 15.8447 21.7637H4.13477C3.10938 21.7637 2.54541 21.21 2.54541 20.1436V10.0947C2.54541 9.02832 3.10938 8.47461 4.13477 8.47461H6.9751V6.82373H4.11426C1.97119 6.82373 0.894531 7.89014 0.894531 10.0024V20.2358C0.894531 22.3481 1.97119 23.4146 4.11426 23.4146Z"
            fill="#2E7CF6"
          />
        </Icon>
        <Icon width="22" height="19" viewBox="0 0 22 19" fill="none">
          <path
            d="M10.9902 2.37744C10.2109 1.39307 8.27295 0.521484 6.10938 0.521484C3.26904 0.521484 0.972168 1.98779 0.408203 3.36182V17.6865C0.408203 18.4658 0.910645 18.7632 1.47461 18.7632C1.91553 18.7632 2.18213 18.6299 2.45898 18.4146C3.04346 17.9121 4.22266 17.2354 6.10938 17.2354C8.00635 17.2354 9.3291 17.8916 9.8418 18.353C10.1084 18.5684 10.4263 18.7632 10.9902 18.7632C11.5542 18.7632 11.8721 18.5479 12.1387 18.353C12.6924 17.9224 13.9844 17.2354 15.8711 17.2354C17.7681 17.2354 18.9473 17.9224 19.5317 18.4146C19.8086 18.6299 20.0649 18.7632 20.5059 18.7632C21.0698 18.7632 21.5825 18.4658 21.5825 17.6865V3.36182C21.0186 1.98779 18.7114 0.521484 15.8711 0.521484C13.7178 0.521484 11.7798 1.39307 10.9902 2.37744ZM2.05908 3.854C2.29492 3.21826 3.78174 2.06982 6.10938 2.06982C8.44727 2.06982 9.96484 3.22852 10.1699 3.854V16.8252C9.1958 16.0972 7.70898 15.687 6.10938 15.687C4.52002 15.687 3.0332 16.0972 2.05908 16.8662V3.854ZM19.9316 3.854V16.8662C18.9473 16.0972 17.4707 15.687 15.8711 15.687C14.2715 15.687 12.7949 16.0972 11.8208 16.8252V3.854C12.0259 3.22852 13.5435 2.06982 15.8711 2.06982C18.209 2.06982 19.6958 3.21826 19.9316 3.854Z"
            fill="#2E7CF6"
          />
        </Icon>
        <Icon width="24" height="23" viewBox="0 0 24 23" fill="none">
          <path
            d="M3.96191 17.626H5.85889V19.3896C5.85889 21.502 6.93555 22.5684 9.07861 22.5684H20.0298C22.1729 22.5684 23.2495 21.502 23.2495 19.3896V8.48975C23.2495 6.3877 22.1729 5.32129 20.0298 5.32129H18.1328V3.66016C18.1328 1.54785 17.0459 0.481445 14.9131 0.481445H3.96191C1.80859 0.481445 0.742188 1.54785 0.742188 3.66016V14.4575C0.742188 16.5698 1.80859 17.626 3.96191 17.626ZM3.98242 15.9751C2.95703 15.9751 2.39307 15.4316 2.39307 14.3652V3.75244C2.39307 2.68604 2.95703 2.13232 3.98242 2.13232H14.8823C15.8975 2.13232 16.4819 2.68604 16.4819 3.75244V5.32129H9.07861C6.93555 5.32129 5.85889 6.37744 5.85889 8.48975V15.9751H3.98242ZM9.10938 20.9175C8.08398 20.9175 7.50977 20.3638 7.50977 19.2974V8.58203C7.50977 7.51562 8.08398 6.97217 9.10938 6.97217H20.0093C21.0244 6.97217 21.5986 7.51562 21.5986 8.58203V19.3076C21.5986 20.3638 21.0244 20.9175 20.0093 20.9175H9.10938Z"
            fill="#2E7CF6"
          />
        </Icon>
      </SafariContent>
      <BottomBar />
    </SafariSpaceContainer>
  );
};

export default SafariSpace;

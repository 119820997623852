import styled from "styled-components";

const StatusBarContainer = styled.div`
  width: 100%;
  height: 47px;
  background-color: #fff; /* 배경 색상 */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  color: #171a1f; /* 글자 색상 */
  font-size: 16px;
  position: relative;
`;

const TopBar = styled.div`
  width: 130px;
  height: 25px;
  background-color: black;
  border-radius: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Time = styled.div`
  font-weight: 500;
  font-size: 15px;
  margin-left: 27px;
`;

const IconsContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-right: 10px;
`;

const Icon = styled.svg``;

const StatusBar: React.FC = () => {
  return (
    <>
      <StatusBarContainer>
        <Time>9:41</Time>
        <TopBar />
        <IconsContainer>
          <Icon width="18" height="12" viewBox="0 0 18 12" fill="none">
            <path
              d="M10 3C10 2.44772 10.4477 2 11 2H12C12.5523 2 13 2.44772 13 3V11C13 11.5523 12.5523 12 12 12H11C10.4477 12 10 11.5523 10 11V3Z"
              fill="#171A1F"
            />
            <path
              d="M15 1C15 0.447715 15.4477 0 16 0H17C17.5523 0 18 0.447715 18 1V11C18 11.5523 17.5523 12 17 12H16C15.4477 12 15 11.5523 15 11V1Z"
              fill="#171A1F"
            />
            <path
              d="M5 6.5C5 5.94772 5.44772 5.5 6 5.5H7C7.55228 5.5 8 5.94772 8 6.5V11C8 11.5523 7.55228 12 7 12H6C5.44772 12 5 11.5523 5 11V6.5Z"
              fill="#171A1F"
            />
            <path
              d="M0 9C0 8.44772 0.447715 8 1 8H2C2.55228 8 3 8.44772 3 9V11C3 11.5523 2.55228 12 2 12H1C0.447715 12 0 11.5523 0 11V9Z"
              fill="#171A1F"
            />
          </Icon>
          <Icon width="17" height="12" viewBox="0 0 17 12" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.50047 2.58753C10.967 2.58764 13.3393 3.55505 15.1269 5.28982C15.2615 5.42375 15.4766 5.42206 15.6092 5.28603L16.896 3.96045C16.9631 3.89146 17.0006 3.798 17 3.70076C16.9994 3.60353 16.9609 3.51052 16.893 3.44234C12.2011 -1.14745 4.79908 -1.14745 0.107163 3.44234C0.0391973 3.51047 0.000634479 3.60345 7.75932e-06 3.70069C-0.00061896 3.79792 0.0367421 3.89141 0.103824 3.96045L1.39096 5.28603C1.52346 5.42226 1.73878 5.42396 1.87331 5.28982C3.66116 3.55494 6.03367 2.58752 8.50047 2.58753ZM8.53591 6.67235C9.89112 6.67227 11.198 7.18644 12.2025 8.11496C12.3384 8.24674 12.5524 8.24389 12.6849 8.10853L13.9702 6.78295C14.0379 6.71342 14.0754 6.61909 14.0744 6.52108C14.0735 6.42306 14.034 6.32954 13.965 6.26142C10.9059 3.35683 6.16852 3.35683 3.10945 6.26142C3.04035 6.32953 3.00092 6.42311 3.00002 6.52115C2.99911 6.6192 3.0368 6.71352 3.10462 6.78295L4.38954 8.10853C4.52199 8.24389 4.73602 8.24674 4.87189 8.11496C5.87578 7.18706 7.18159 6.67293 8.53591 6.67235ZM11.1496 9.34267C11.1515 9.44096 11.1137 9.53573 11.0449 9.60459L8.82165 11.8948C8.75648 11.9621 8.66762 12 8.57491 12C8.4822 12 8.39334 11.9621 8.32817 11.8948L6.10452 9.60459C6.03583 9.53568 5.99804 9.44088 6.00008 9.34259C6.00212 9.2443 6.0438 9.15122 6.11528 9.08534C7.53515 7.8595 9.61467 7.8595 11.0345 9.08534C11.106 9.15128 11.1476 9.24438 11.1496 9.34267Z"
              fill="#171A1F"
            />
          </Icon>
          <Icon width="28" height="13" viewBox="0 0 28 13" fill="none">
            <path
              opacity="0.35"
              d="M0.527543 4C0.527543 2.08221 2.08221 0.527543 4 0.527543H21C22.9178 0.527543 24.4725 2.08222 24.4725 4V9C24.4725 10.9178 22.9178 12.4725 21 12.4725H4C2.08222 12.4725 0.527543 10.9178 0.527543 9V4Z"
              fill="#171A1F"
              stroke="#171A1F"
              strokeWidth="1.05509"
            />
            <path
              opacity="0.4"
              d="M26 5V9.22034C26.8491 8.86291 27.4012 8.0314 27.4012 7.11017C27.4012 6.18894 26.8491 5.35744 26 5Z"
              fill="#171A1F"
            />
            <path
              d="M2 4C2 2.89543 2.89543 2 4 2H21C22.1046 2 23 2.89543 23 4V9C23 10.1046 22.1046 11 21 11H4C2.89543 11 2 10.1046 2 9V4Z"
              fill="#171A1F"
            />
          </Icon>
        </IconsContainer>
      </StatusBarContainer>
    </>
  );
};

export default StatusBar;
